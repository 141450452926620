import { CSSTransition } from "react-transition-group";
import classNames from "classnames";
import { motion } from "framer-motion";
import type { Transition } from "framer-motion/types/types";
import { v4 as uuidv4 } from "uuid";
import "./loader.less";

type TSize = "large" | "medium" | "small";

const getSideVariants = (index: number) => {
    const opacity = [...new Array(7).fill(1), 0];

    for (let i = 0; i < index; i++) {
        opacity[i] = 0;
    }

    return {
        animate: {
            opacity,
            transition: {
                duration: 1.2,
                type: "spring",
                ease: "easeInOut",
                repeat: Infinity,
                repeatType: "loop",
                times: [0, 0.167, 0.333, 0.5, 0.667, 0.833, 0.99, 1],
            } as Transition,
        },
    };
};

const HexagonIndicator = () => {
    return (
        <motion.div className="loader" animate="animate">
            {Array.from({ length: 6 }).map((_, index) => {
                return (
                    <svg
                        key={uuidv4()}
                        className={classNames("side", `side-${index + 1}`)}
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <mask id="mask0_3709_3894" maskUnits="userSpaceOnUse" x="0" y="1">
                            <path
                                d="M4.03991 2.69377C4.32923 2.18747 4.86766 1.875 5.45081 1.875H10.5492C11.1323 1.875 11.6708 2.18746 11.9601 2.69377L14.5315 7.19377C14.817 7.69335 14.817 8.30665 14.5315 8.80623L11.9601 13.3062C11.6708 13.8125 11.1323 14.125 10.5492 14.125H5.45081C4.86766 14.125 4.32923 13.8125 4.03991 13.3062L1.46848 8.80623C1.18301 8.30665 1.18301 7.69335 1.46848 7.19377L4.03991 2.69377Z"
                                stroke="#FF00FF"
                                strokeWidth="1.75"
                            />
                        </mask>
                        <g mask="url(#mask0_3709_3894)">
                            <motion.path
                                d="M12 1L8 8H16L12 1Z"
                                fill="#F0CF7F"
                                variants={getSideVariants(index + 1)}
                            />
                        </g>
                    </svg>
                );
            })}
        </motion.div>
    );
};

type TProps = {
    size?: TSize;
    show: boolean;
    outline?: boolean;
    className?: string;
    useOverlay?: boolean;
    fullScreenMode?: boolean;
};

export const StlLoader = ({
    show,
    useOverlay = false,
    outline = false,
    className,
    size = "medium",
    fullScreenMode = false,
}: TProps) => {
    if (!show) return null;

    if (fullScreenMode) {
        return (
            <div id="stl-loader" className={classNames("stl-loader", className)}>
                {useOverlay && <div className="overlay" />}
                <div className={classNames("loader-wrapper large", outline && "outline")}>
                    <HexagonIndicator />
                </div>
            </div>
        );
    }

    return (
        <div className={classNames("stl-loader", className)}>
            {useOverlay && <div className="overlay" />}
            <div className={classNames("loader-wrapper", size, outline && "outline")}>
                <HexagonIndicator />
            </div>
        </div>
    );
};
