import classNames from "classnames";
import { StlLoader } from "@common/components";
import "./mapLoader.less";

type TProps = {
    show: boolean;
    className?: string;
    useOverlay?: boolean;
};

export const StlMapLoader = ({ show, useOverlay = false, className }: TProps) =>
    show ? (
        <div className={classNames("stl-map-loader", className)}>
            {useOverlay && <div className="overlay" />}
            <div className={classNames("map-loader-wrapper")}>
                <StlLoader show size="small" />
                Fetching data...
            </div>
        </div>
    ) : null;
